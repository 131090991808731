import React, { Component } from 'react'
import Link from '../utils/link'
import Slider from 'react-slick'
import Fade from 'react-reveal/Fade'

import { RightArrow } from './icons'
import BoatCard from './cards/boat'

function PrevArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#000000'} />
    </span>
  )
}

function NextArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className }
  return (
    <span 
      {...attr}
    >
      <RightArrow color={'#000000'} />
    </span>
  )
}

class FeaturedBoats extends Component {

  render() {

    let { title, boats } = this.props

    const settings = {
      speed: 600,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      <section className='featured-properties'>
        <Fade bottom distance='30px'>
          <div className='featured-properties__inner'>
            <div className='featured-properties__title'>
              <h3>{title}</h3>
            </div>
            <div className='featured-properties__items'>
              { boats &&
                <Slider {...settings}>
                  { boats.map((el, i) => (
                    <BoatCard key={i} {...el} />
                  ))}
                  { boats.map((el, i) => (
                    <BoatCard key={i} {...el} />
                  ))}
                </Slider>
              }
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default FeaturedBoats
