import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'

import { Arrow } from './icons'

class BuySell extends Component {

  render() {

    let { buy, sell } = this.props

    return (
      <section className='buy-sell'>
        <Fade bottom distance='30px'>
          <div className='buy-sell__buy'>
            <Link to='/buy/'>
              <GatsbyImage loading='lazy' image={getImage(buy.image?.localFile)} alt='Buy' />
              <span>Buy</span>
              <Arrow />
            </Link>
          </div>
        </Fade>
        <Fade bottom distance='30px'>
          <div className='buy-sell__sell'>
            <Link to='/sell/'>
              <GatsbyImage loading='lazy' image={getImage(sell.image?.localFile)} alt='Sell' />
              <span>Sell</span>
              <Arrow />
            </Link>
          </div>
        </Fade>
      </section>
    )
  }
}

export default BuySell