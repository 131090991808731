import React from 'react'

import SearchBanner from '../components/search-banner'
import BasicContent from '../components/basic-content'
import BuySell from '../components/buy-sell'
import FeaturedBoats from '../components/featured-boats'
import CtaImage from '../components/cta-image'
import CtaForm from '../components/cta-form'
import PageHeader from '../components/page-header'
import TitleContactContent from '../components/title-contact-content'
import ContactFormBlock from '../components/contact-form-block'

const renderBlock = (param, el, i, postType, func=null) => {
  param = param?.replace(postType, '')
  let block = {
  	'_Acf_Components_SearchBanner' : (el, i) => <SearchBanner key={i} {...el} />,
    '_Acf_Components_BasicContent' : (el, i) => <BasicContent key={i} {...el} />,
    '_Acf_Components_BuySell' : (el, i) => <BuySell key={i} {...el} />,
    '_Acf_Components_FeaturedBoats' : (el, i) => <FeaturedBoats key={i} {...el} />,
    '_Acf_Components_CtaImage' : (el, i) => <CtaImage key={i} {...el} />,
    '_Acf_Components_CtaForm' : (el, i) => <CtaForm key={i} {...el} />,
    '_Acf_Components_PageHeader' : (el, i) => <PageHeader key={i} {...el} />,
    '_Acf_Components_TitleContactContent' : (el, i) => <TitleContactContent key={i} {...el} />,
    '_Acf_Components_ContactForm' : (el, i) => <ContactFormBlock key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock