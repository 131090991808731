import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FormCta from './form-cta'

class CtaForm extends Component {

  render() {

    let { title, content, backgroundImage } = this.props

    return (
      <section className='cta-form'>
        { backgroundImage && <GatsbyImage className='cta-form__bg' image={getImage(backgroundImage.localFile)} alt={title} /> }
        <div className='cta-form__inner'>
          <div className='cta-form__content'>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className='cta-form__form'>
            <FormCta />
          </div>
        </div>
      </section>
    )
  }
}

export default CtaForm
