import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'

class PageHeader extends Component {

  render() {

    let { backgroundImage, title } = this.props

    return (
      <section className='page-header'>
        <div className='page-header__inner'>
          <div className='page-header__wrapper'>
            { backgroundImage && <GatsbyImage image={getImage(backgroundImage.localFile)} alt={title} /> }
            <div className='page-header__content'>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PageHeader
