import React, { Component } from 'react'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'

class BasicContent extends Component {

  render() {

    let { title, button } = this.props

    return (
      <section className='basic-content'>
        <Fade bottom distance='30px'>
          <div className='basic-content__inner'>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <Link className='btn' to={button.link}>{button.text}</Link>
          </div>
        </Fade>
      </section>
    )
  }
}

export default BasicContent
