import React, { Component } from 'react'
import Search from './search'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class SearchBanner extends Component {

  render() {

    let { backgroundImage, title, video } = this.props

    return (
      <section className='search-banner'>
        <video src={video} poster={backgroundImage.localFile?.childImageSharp?.original?.src} autoPlay loop muted playsInline />
        <div className='search-banner__inner'>
          <div className='search-banner__form'>
            <h4>{title}</h4>
            <Search home={true} />
          </div>
        </div>
      </section>
    )
  }
}

export default SearchBanner
