import React, { Component } from 'react'
import Link from '../utils/link'
import ContactForm from './contact-form'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Arrow } from './icons'

class TitleContactContent extends Component {

  render() {

    let { title, contact, content, form, buyImage, sellImage } = this.props

    return (
      <section className='title-contact'>
        <div className='title-contact__inner'>
          <div className='title-contact__title'>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className='title-contact__contact' dangerouslySetInnerHTML={{ __html: contact }} />
          </div>
          <div className='title-contact__content'>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            { form &&
              <ContactForm />
            }
            { buyImage &&
              <div className='title-contact__buy'>
                <Link to='/buy/'>
                  <GatsbyImage loading='lazy' image={getImage(buyImage?.localFile)} alt='Buy' />
                  <span>Buy</span>
                  <Arrow />
                </Link>
              </div>
            }
            { sellImage &&
              <div className='title-contact__sell'>
                <Link to='/sell/'>
                  <GatsbyImage loading='lazy' image={getImage(sellImage?.localFile)} alt='Sell' />
                  <span>Sell</span>
                  <Arrow />
                </Link>
              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default TitleContactContent
