import React, { Component } from 'react'
import Link from '../utils/link'
import ContactForm from './contact-form'

class ContactFormBlock extends Component {

  render() {

    let { call, email, location, social } = this.props

    return (
      <section className='contact-block'>
        <div className='contact-block__inner'>
          <div className='contact-block__details'>
            <div className='call'>
              <h4>{call.title}</h4>
              { call.button.link && <Link to={call.button.link}>{call.button.text}</Link> }
            </div>
            <div className='email'>
              <h4>{email.title}</h4>
              { email.button.link && <Link to={email.button.link}>{email.button.text}</Link> }
            </div>
            <div className='location'>
              <h4>{location.title}</h4>
              { location.button.link && <Link to={location.button.link}>{location.button.text}</Link> }
            </div>
            <div className='social'>
              <h4>{social.title}</h4>
              <ul>
                { social.menu.length > 0 && social.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.button.link}>{el.button.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='contact-block__form'>
            <ContactForm extended={true} />
          </div>
        </div>
      </section>
    )
  }
}

export default ContactFormBlock
