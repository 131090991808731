import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={page.seo?.title} bodyClass={page.slug} description={page.seo?.metaDesc} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page')) }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_SearchBanner {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                  gatsbyImageData(
                    quality: 100
                    width: 1400
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            video
            title
          }
          ... on WpPage_Acf_Components_BasicContent {
            fieldGroupName
            title
            button {
              text
              link
            }
          }
          ... on WpPage_Acf_Components_BuySell {
            fieldGroupName
            buy {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 1000
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            sell {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 1000
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_FeaturedBoats {
            fieldGroupName
            title
            boats {
              ... on WpBoat {
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          width: 600
                          formats: [AUTO, WEBP, AVIF]
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                }
                acf {
                  specs {
                    size
                    beds
                    baths
                  }
                  price {
                    displayPrice
                  }
                  excerpt
                }
              }
            }
          }
          ... on WpPage_Acf_Components_CtaImage {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1400
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            content
            button {
              text
              link
            }
          }
          ... on WpPage_Acf_Components_PageHeader {
            fieldGroupName
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1400
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
          }
          ... on WpPage_Acf_Components_TitleContactContent {
            fieldGroupName
            title
            contact
            content
            form
            buyImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1000
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            sellImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1000
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
          ... on WpPage_Acf_Components_ContactForm {
            fieldGroupName
            call {
              title
              button {
                text
                link
              }
            }
            email {
              title
              button {
                text
                link
              }
            }
            location {
              title
              button {
                text
                link
              }
            }
            social {
              title
              menu {
                button {
                  text
                  link
                }
              }
            }
          }
          ... on WpPage_Acf_Components_CtaForm {
            fieldGroupName
            title
            content
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 1400
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
