import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'

class CtaImage extends Component {

  render() {

    let { backgroundImage, title, content, button } = this.props

    return (
      <section className='cta-image'>
        <div className='cta-image__inner'>
          <Fade bottom distance='30px'>
            <div className='cta-image__wrapper'>
              { backgroundImage && <GatsbyImage image={getImage(backgroundImage.localFile)} alt={title} /> }
              <div className='cta-image__content'>
                <div>
                  <h3>{title}</h3>
                  <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                <Link className='btn' to={button.link}>{button.text}</Link>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

export default CtaImage
